.footer-area{
    width: 100%;
    float: left;
    padding: 50px 15px 10px 15px;
    background: #f5f7fa;
}
img.footerlogo {
    width: 80px;
    object-fit: cover; 
}
.footer-area h3{
    color: #000;
    position: relative; font-size: 22px;
}

.text-gray{
    color: #999;
}
iframe.iframe {
    width: 90%;
    height: 210px;
    border: solid 5px #f8ffff;
}
.company-list-link li {
    width: 100%;
    margin-top: 5px; margin-bottom: 5px;
    color: #8b8888; display: flex;
}
.company-list-link li a{

    color: #000;
}
.company-list-link li span {
    width: 20px;
    float: left;
    color: #b8b4b8;
    font-size: 18px;
}
.company-list-link li label {
    width: 87%;
    float: left;
    /* border: solid; */
    margin: 3px 4px;
}

.social-icon{
    width: 100%;
    float: left;
    margin-top: 24px;
  }
  .social-icon button {
    color: #CECECE;
    border: solid 1px #CECECE;
    width: 32px;
    height: 32px;
    border-radius: 40px;
    margin-right: 13px;
    text-align: center;
    padding: 0px;
    font-size: 15px;
  }

  .social-icon button:hover{
background: #791887; color: #fff;
  }
  .footer-bottom p {
    font-size: 15px;
    margin-top: 42px;color: rgb(163, 161, 161);
}
.footer-bottom ul {
    display: flex;
    direction: rtl;  margin-top: 42px;
}
.footer-bottom ul li {
    padding-left: 31px;
    font-size: 15px;
}
.footer-bottom ul li a{
   color: #999;
}
.footer-bottom {
    border-top: solid 1px #e9e8e8;

 float: left; width: 100%;
}
.social-icon button svg {
    top: -2px;
    position: relative;
}
.back-to-top {
    width: 40px;
    height: 40px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    z-index: 999;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(90deg, #7e1987 0%, #0199fe 100%); 
    border: none;    padding-top: 0px; position: relative; border-radius: 40px;
}
.back-to-top svg{
    fill: #fff;
}
.back-to-top:after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
@media (min-width:200px) and (max-width:767px) {
    img.logo-top {

        margin: auto;
        display: table;
    }
    .footer-area p{
        text-align: center;
    }
    .footer-area h3{
        text-align: center;margin-top: 30px;
    }
    .social-icon{
        text-align: center;
    }
    .company-list-link li{
        text-align: center;
    }
    .company-list-link li{
        display: block; margin-top: 0px;
        margin-bottom: 0px;
    }
    .company-list-link li span{
        display: none;
    }
    

}